import React from "react";
import img1 from "../Assets/md1.png";
import img2 from "../Assets/md2.png";
import "../Styles/MedicalBilling.css";
function MedicalBilling() {
  return (
    <div className="medical-cover">
      <div className="medical-head">
        <h1
          className="medical-heading"
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Medical Billing - What we do ??
        </h1>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          We are one of the leading medical billing outsourcing company offering
          high quality services to our clients. Our current information
          Databases helps us perform greater in-depth analysis and research,
          which in turn translate to accuracy and reliability in medical claims
          management. We have experience with most of the popular billing
          software applications.
        </p>
        <br />
        <p>
          We use HIPAA compliant VPN (Virtual Private Networks) to access your
          systems. Our staff will perform the medical billing functions on your
          systems, exactly the way they used to be done, the only difference
          being that they would be physically in our facility. This means that
          all your processes and reports remain exactly the same.
        </p>
      </div>
      <div
        className="medical-first"
        data-aos="zoom"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
      >
        <div className="medical-first-img">
          <img
            src={img1}
            alt="medical billing"
            data-aos="zoom"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
          />
        </div>
        <div
          className="medical-first-content"
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <h2>Demographics & Charges</h2>
          <p>
            We have well qualified staffs to enter the PT demographics and
            Charges which has been received from the provider, Work has been
            done within the TAT to avoid claims late submissions and reduce the
            denials from payer, More than work audit is done on the same day
            before billing the claim to insurance and Eligibility is verified
            every time before billing a claim.
          </p>
          <br />
          <h2>Payment Posting</h2>
          <p>
            Payment posting has been done in two ways by Manual and ERA. If
            denials found in ERA they are done by manual by obtaining EOB to
            avoid incorrect denial posting and valid EOB to AR process to
            resolve the denial easily. Payment is posted with High accuracy as
            per EOB and payment reports will be updated to clients in daily
            bases.
          </p>
        </div>
      </div>
      <div className="medical-second">
        <div
          className="medical-second-content"
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <h2
            data-aos="zoom"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
          >
            Accounts Receivable
          </h2>
          <p
            data-aos="zoom"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
          >
            We understand the importance of accurately and quickly processing
            your claims so that it will help you to maximize your revenue and
            improve your cash flow. Priority is given to old aged claims to
            reduce balance adjustments. AR actions can be done on a daily basis
            and assistance from client to increase in collection ratio through
            accurate analysis and timely followup. Old AR mare also analyzed and
            corrective measures will be taken.
          </p>
          <br />
          <h2
            data-aos="zoom"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
          >
            PT Statements
          </h2>
          <p
            data-aos="zoom"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
          >
            Patient statements have been done twice in a month based on client
            instructions and requirement. Level of Patient has been set to
            obtain from patient without moving them to collections.
          </p>
        </div>
        <div
          className="medical-second-img"
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <img src={img2} alt="medical billing" />
        </div>
      </div>
      <div className="medical-third">
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Outsource Medical Billing and Coding Services
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          We streamline medical billing process for inpatient & outpatient
          medical practitioners, doctors, & healthcare insurance companies
        </p>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Does your medical facility want to focus on core medical functions,
          minimize billing denials, increase efficiency in the billing process,
          cut down on costs, and ensure timely reimbursement all at the same
          time?
        </p>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Outsourcing medical billing and coding to skilled offshore service
          providers like Outsource2india can help you avail all the
          above-mentioned benefits without any hassles. As our customer, your
          medical facility can get access to quality medical coding and billing,
          while reducing your current operating costs by at least 40%. Our
          end-to-end medical billing and coding solutions can meet all your
          needs, from following up on pending medical claims, to getting reasons
          for denied claims, tracking receivable balances, or initiating
          collections.
        </p>
        <br />
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Our Medical Billing & Coding Solutions
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Having been in the medical billing and coding industry for almost two
          decades now, we have the required experience and expertise to cater to
          any of the client’s needs. We offer an entire gamut of medical billing
          and coding services that include –
        </p>
        <div
          className="medical-third-table"
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <div className="medical-table-left">
            <ul
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              <li>
                <h3> Medical Billing</h3>
              </li>
              <li>
                <h3>Medical Coding</h3>
              </li>
              <li>
                <h3>Physician Billing Services</h3>
              </li>
              <li>
                <h3> Patient Demographic Entry</h3>
              </li>
              <li>
                <h3> Medical Data Entry Services</h3>
              </li>
              <li>
                <h3>ICD-10 and CPT Coding</h3>
              </li>
              <li>
                <h3> Indexing Medical Records</h3>
              </li>
              <li>
                <h3> Enrollment and Credentialing Services</h3>
              </li>
            </ul>
          </div>
          <div className="medical-table-right">
            <ul
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              <li>
                <h3>Physician Credentialing Services</h3>
              </li>
              <li>
                <h3> EMS Billing Services</h3>
              </li>
              <li>
                <h3> DME Billing Services</h3>
              </li>
              <li>
                <h3> Medical Document Scanning Services</h3>
              </li>
              <li>
                <h3> Charge Entry</h3>
              </li>
              <li>
                <h3> Emergency Medicine Billing Services</h3>
              </li>
              <li>
                <h3> Claims Submission</h3>
              </li>
              <li>
                <h3>Appointment Scheduling</h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="medical-fourth">
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Our Medical Billing Specialties
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Our medical billing services range across a variety of healthcare
          specialties within your practice.
        </p>
        <div className="medical-fourth-table">
          <div
            className="medical-fourth-table-left"
            data-aos="zoom"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
          >
            <ul>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Hospital Billing</h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Hospice Billing Services </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Pediatric Billing Services</h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Chiropractic Billing </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Urology Billing Services </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Pathology Billing </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Mental Health Billing </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Ambulance Billing Services </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Radiology Medical Billing Services</h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Cardiology Medical Billing Services </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Gynecology Billing Services </h3>
              </li>
              <li
                data-aos="zoom"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <h3>Neurology Medical Billing Services</h3>
              </li>
            </ul>
          </div>
          <div className="medical-fourth-table-right">
            <ul
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              <li>
                <h3>Plastic Surgery Billing</h3>
              </li>
              <li>
                <h3>Family Practice Billing Services</h3>
              </li>
              <li>
                <h3>Otolaryngology Billing Services</h3>
              </li>
              <li>
                <h3>Occupational Health Billing Services</h3>
              </li>
              <li>
                <h3>Anesthesia Billing Services</h3>
              </li>
              <li>
                <h3>Surgery Center Billing Services</h3>
              </li>
              <li>
                <h3>Orthopedic Medical Billing Services</h3>
              </li>
              <li>
                <h3>Sleep Medicine Billing Services</h3>
              </li>
              <li>
                <h3>Dental Billing Services</h3>
              </li>
              <li>
                <h3>Urgent Care Billing Services</h3>
              </li>
              <li>
                <h3>Physical Therapy Billing Services</h3>
              </li>
              <li>
                <h3>Workers Compensation Billing Services</h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="medical-fifth"
        data-aos="zoom"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
      >
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Why Should Your Hospital or Healthcare Facility Outsource Billing &
          Coding?
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Here is why your hospital, clinic, or multi-specialty group should
          consider outsourcing medical billing and coding –
        </p>
        <ul
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <li>
            <p>
              Save on infrastructure-related (hardware and software) expenses
            </p>
          </li>
          <li>
            <p>
              Minimize your current billing and coding costs by a whopping
              40-60%!
            </p>
          </li>
          <li>
            <p>No need to pay for employee benefits or employee absenteeism</p>
          </li>
          <li>
            <p>No billing and coding staff turnover</p>
          </li>
          <li>
            <p>Eliminate the need to train new resources</p>
          </li>
          <li>
            <p>
              Experience a drastic reduction in denials with accurate data
              management
            </p>
          </li>
          <li>
            <p>
              Get faster reimbursements with accurate processing and submission
              of claims
            </p>
          </li>
          <li>
            <p>Benefit from zero billing errors</p>
          </li>
          <li>
            <p>Get fewer denials with error-free claims</p>
          </li>
          <li>
            <p>Improved and steady cash flow</p>
          </li>
          <br />
          <li>
            <h4>24 hours</h4>
          </li>
          <li>
            <h4>TAT</h4>
          </li>
          <li>
            <h4>98% Accuracy</h4>
          </li>
          <li>
            <h4>40% Cost Reduction</h4>
          </li>
          <li>
            <h4>300+ Customers</h4>
          </li>
          <li>
            <h4>07 years Experience</h4>
          </li>
        </ul>
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Tools Leveraged for Medical Billing and Coding Services
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Being one of the leading providers of medical billing and coding
          services, we believe in providing our clients with the best quality
          services within a quick time. This is made possible by making use of
          the best and the latest medical billing and coding tools and
          technologies. Some of the key tools and technologies leveraged by our
          team include –
        </p>
        <br />
        <h3
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Get Our Experts to Work for You
        </h3>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          One of our major differentiators is our skilled team of billing and
          coding experts who have in-depth knowledge and hands-on experience in
          working with Medicare, HIPAA, and Medicaid regulations. Our team has a
          thorough understanding of procedural and diagnostic coding, accounts
          payable management and electronic medical claims submission.
        </p>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Our skilled medical billing and coding experts are proficient with –
        </p>
        <ul
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <li>
            <p>ICD-10, CPT and HCPCS codes across different specialties</p>
          </li>
          <li>
            <p>Payer-specific medical coding requirements</p>
          </li>
          <li>
            <p>Governmental, regulatory and insurance requirements</p>
          </li>
        </ul>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Our certified medical coders (CPC) keep themselves updated with
          constantly-changing trends in the medical industry, especially in
          ICD-10, CPT and HCPCS codes. We also stay current with the latest
          versions of medical coding software such as Encoder Pro and Flash
          Code.
        </p>
        <h3
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Our Clientele
        </h3>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          For the past 07 years, we have provided customized medical billing and
          coding services to varied types of medical facilities. Our customers
          include –
        </p>
        <ul
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <li>
            <p>Hospitals</p>
          </li>
          <li>
            <p>Medical billing companies</p>
          </li>
          <li>
            <p>Individual physicians and physician groups</p>
          </li>
          <li>
            <p>Clearing houses</p>
          </li>
          <li>
            <p>Multi-specialty groups</p>
          </li>
          <li>
            <p>Dental practices</p>
          </li>
          <li>
            <p>DME and pharmacy companies</p>
          </li>
          <li>
            <p>Healthcare clinics</p>
          </li>
          <li>
            <p>Payer organizations</p>
          </li>
          <li>
            <p>Long term care & acute care facilities</p>
          </li>
          <li>
            <p>Home health care agencies</p>
          </li>
        </ul>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          As your partner in medical billing and coding, we can customize our
          services according to the unique requirements of your healthcare
          facility.
        </p>
        <br />
        <p>
          Read our article on top 8 medical billing and coding errors you should
          avoid.
        </p>
        <br />
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Why Choose Clinics4Health for Medical Billing & Coding Services?
        </h2>
        <p>
          Outsourcing medical billing and coding services to Clincs4Health can
          give you access to a series of benefits which you can take advantage
          of. Some of the key reasons for you to choose us include –
        </p>
        <ul
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          <li>
            <h3>Affordable Pricing</h3>
          </li>
          <p>
            Working with us as your outsourcing partner is highly cost-effective
            and easy. We provide our clients with highly affordable pricing
            options which will suit their budget and business requirement.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Information Security
            </h3>
          </li>
          <p>
            We take patient-related data and medical records safety very
            seriously. We are an ISO: ISMS certified organization which ensures
            that all your patient data and other medical records are kept
            completely safe.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              High-quality Services
            </h3>
          </li>
          <p>
            We believe in providing our clients with the best quality medical
            billing and coding services. We are an ISO certified organization
            which ensures that all the services we deliver are error-free and of
            the best quality.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              HIPAA Compliance
            </h3>
          </li>
          <p>
            We begin every service only after signing the HIPAA agreement. This
            ensures that all your patient data and medical records are kept safe
            and not divulged to any third-party for any reason.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Reduction in the Number of Denials
            </h3>
          </li>
          <p>
            Flaws in coding or inaccuracies in data are the main causes for
            claims getting denied. With the skilled Outsource2india billing and
            coding team handling your claims, you will see a sharp reduction in
            the number of denials in just a few days.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Regular Reports
            </h3>
          </li>
          <p>
            Our team will keep you updated about the status of your claims, by
            providing you with daily, weekly, and monthly progress reports. The
            reports will include details about productivity, procedure code
            analysis, projections, and pending problems if any.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Process-driven Approach
            </h3>
          </li>
          <p>
            We follow a process that includes steps such as Insurance
            Eligibility Verification, Patient Demographic Entry, CPT/ ICD-10
            Coding, Charge Entry, Claims Submission, Accounts Receivables
            Follow-up, Payment Posting, Denial Analysis, and Accounts
            Receivables Management.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Customized Solutions
            </h3>
          </li>
          <p>
            We are very flexible and can provide you with customized medical
            billing and coding services to meet your specific requirements.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Medical Billing and Coding for Varied Specialties
            </h3>
          </li>
          <p>
            At Clinics4Health we have expertise in working with multiple
            specialties, such as microbiology, ophthalmology, radiology,
            pulmonary, obstetrics and gynecology, neurology, anesthesiology,
            cardiology, critical care, endocrinology, emergency surgery, general
            surgery, and gastroenterology, amongst others.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Easily Scalable
            </h3>
          </li>
          <p>
            Our team of medical billing and coding experts have the required
            skills, talent, resources, and bandwidth to easily ramp up the
            medical billing and coding service requirement as and when the
            client needs it.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              Single Point of Contact
            </h3>
          </li>
          <p>
            When you choose to outsource medical billing and coding to us, we
            will assign you a dedicated project manager who will be the single
            point of contact. The manager will keep you updated about the latest
            updates about the project.
          </p>
          <li>
            <h3
              data-aos="zoom"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
            >
              24/7 Availability
            </h3>
          </li>
          <p>
            We provide our clients with round the clock support. All our teams
            including the call center agents, sales teams, and our project
            managers are available at all times to answer your queries and solve
            any issues that you may have.
          </p>
        </ul>
      </div>
      <div className="medical-sixth">
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Provided patient Demographics and Charge Entry to A top US Medical
          Billing Company
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Clinics4Health handled the complete medical billing process for a
          large medical billing company based in the US, improving productivity
          by 32% with error rates below 2.5%. We processed 145 files a month
          with an error rate of just 2%.
        </p>
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Outsource Medical Billing and Coding Services to Clinics4Health.
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Clinics4Health has been a leading provider of medical billing and
          coding services in India and a plethora of other healthcare BPO
          services to clients around the globe. Our team comprises some of the
          most talented and skilled medical billing and coding experts who have
          over 100+ man years of experience in the field of medical billing and
          coding.
        </p>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          We can efficiently process your claims overnight, while your medical
          staff concentrates on providing better healthcare to patients. As our
          customer, you can be assured of fast, streamlined, and accurate
          processing of your billing and coding operations. Learn 6 effective
          ways to collect patient balances.
        </p>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Get in touch with us today and leverage the advantages of outsourcing
          medical coding and billing to India.
        </p>
        <h2
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Does your medical facility want to focus on core medical functions,
          minimize billing denials, increase efficiency in the billing process,
          cut down on costs, and ensure timely reimbursement all at the same
          time?
        </h2>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Outsourcing medical billing and coding to skilled offshore service
          providers like Clincs4Health can help you avail all the
          above-mentioned benefits without any hassles. As our customer, your
          medical facility can get access to quality medical coding and billing,
          while reducing your current operating costs by at least 40%. Our
          end-to-end medical billing and coding solutions can meet all your
          needs, from following up on pending medical claims, to getting reasons
          for denied claims, tracking receivable balances, or initiating
          collections.
        </p>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Our Medical Billing & Coding Solutions
        </p>
        <br />
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Having been in the medical billing and coding industry for almost two
          decades now, we have the required experience and expertise to cater to
          any of the client’s needs. We offer an entire gamut of medical billing
          and coding services that include –
        </p>
        <p
          data-aos="zoom"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
        >
          Medical Billing Medical Coding Physician Billing Services Patient
          Demographic Entry Medical Data Entry Services ICD-10 and CPT Coding
          Indexing Medical Records Enrollment and Credentialing Services
          Physician Credentialing Services EMS Billing Services DME Billing
          Services Medical Document Scanning Services Charge Entry Emergency
          Medicine Billing Services Claims Submission Appointment Scheduling
        </p>
      </div>
    </div>
  );
}

export default MedicalBilling;
