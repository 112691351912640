export const customerReviews = [
  {
    name: "Stacy",
    location: "Medical practitioner",
    message:
      "The team at Clinics4Health have been great partners to my billing company. They understand the US healthcare system and the specific needs of working with multiple clients and different states. The leadership of Tej was valuable to me personally while trying to navigate a large client change and I appreciate all of the extra work that he did to ensure that we were well taken care of. I highly recommend this group.",
  },
  {
    name: "John Doe",
    location: "California, USA",
    message:
      "I found the perfect specialist for my condition through Health Plus. The personalized treatment plan made all the difference. Thank you for prioritizing my health!",
  },
  {
    name: "Alice Smith",
    location: "New York, USA",
    message:
      "Booking appointments was a breeze, and the service exceeded my expectations. The doctors truly listen and provide effective solutions. 5 stars!",
  },
  {
    name: "Bob Johnson",
    location: "Florida, USA",
    message:
      "Health Plus is a game-changer! The emergency care saved me during a critical situation. Grateful for their quick and efficient support.",
  },
  {
    name: "Jane Brown",
    location: "Washington, USA",
    message:
      "I used to dread dental visits, but Health Plus made it a pleasant experience. The dentist was gentle and professional. I'll definitely be back!",
  },
  {
    name: "Robert Wilson",
    location: "Texas, USA",
    message:
      "Finally, a healthcare platform that puts patients first! The heart disease service provided comprehensive care and regular follow-ups. Thank you, Health Plus!",
  },
  {
    name: "Mary Lee",
    location: "California, USA",
    message:
      "I've been using Health Plus for a variety of health concerns, and each time, I received exceptional care. It's my go-to for all medical needs!",
  },
  {
    name: "David Miller",
    location: "Texas, USA",
    message:
      "I highly recommend Health Plus for online consultations. It's convenient, secure, and the doctors are top-notch. Great job, team!",
  },
  {
    name: "Sarah Johnson",
    location: "Florida, USA",
    message:
      "The convenience of accessing medical notes online was fantastic. Health Plus made managing my health records hassle-free! Best Healthcare services.",
  },
  {
    name: "Michael Brown",
    location: "New York, USA",
    message:
      "As a busy professional, Health Plus has been a lifesaver. Quick prescriptions and refills without compromising on quality care. Thank you!",
  },
];
